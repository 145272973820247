import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet, httpPost } from '../../action/http';

// export const userId = localStorage.getItem('userId');

export const ProfileContext = createContext();

export const ProfileContextProvider = (props) => {
  const { children } = props;
  const [profile, setProfile] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [reload, setReload] = useState(false);
  const [referenceCode, setReferralCode] = useState('');
  const [isBvnLinked, setIsBvnLinked] = useState(true);
  const [verifiedEmailReload, setVerifiedEmailReload] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(true);

  const getProfileState = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/get-by-user-id?user_id=${userId}`
    );
    if (res?.status) {
      setProfile(res?.data);
    }
  };
  const validateUser = async () => {
    const res = await httpPost(
      `/api/v1/auth/validate-user`,
      {},
      customBaseUrl.authUrl
    );
    console.log(res);
    if (res?.status === true) {
      console.log(res?.data?.isEmailVerified, 'from profile context');
      setEmailVerified(res?.data?.isEmailVerified);
      setPhoneVerified(res?.data?.phoneVerified);
      setReferralCode(res?.data?.referenceCode);
      setUserDetails(res?.data);
    }
  };

  const getUserState = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/user/${Number(userId)}`
    );
    if (res?.status) {
      // console.log(res?.data?.phoneVerified);
      // setEmailVerified(res?.data?.isEmailVerified);
      // setPhoneVerified(res?.data?.phoneVerified);
      // setUserDetails(res?.data);
    }
  };

  // const getReferralCode = async (userId) => {
  //   const res = await httpGet(
  //     `${customBaseUrl.authUrl}/api/v1/referral/referral-code/${Number(userId)}`
  //   );
  //   if (res?.status) {
  //     setReferralCode(res?.data?.referenceCode);
  //   }
  // };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getUserState(userId);
  }, [reloadUser]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getProfileState(userId);
    //  getReferralCode(userId);
    validateUser();
  }, [reload]);

  return (
    <ProfileContext.Provider
      value={{
        setProfile,
        profile,
        reload,
        setReload,
        referenceCode,
        isBvnLinked,
        setIsBvnLinked,
        setVerifiedEmailReload,
        verifiedEmailReload,
        userDetails,
        setReloadUser,
        reloadUser,
        setPhoneVerified,
        phoneVerified,
        setEmailVerified,
        emailVerified,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
