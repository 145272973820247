import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Scrollbars } from 'react-custom-scrollbars-2';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import dashboardIcon from '../../../assets/image/Dashboard Icon.svg';
import wayagramIcon from '../../../assets/image/Wayagram Icon.svg';
import wayapos from '../../../assets/image/wayapos.svg';
import wayabank from '../../../assets/image/wayabank.svg';
import bidIcon from '../../../assets/bid_Icon.svg';
import chatAndCallIcon from '../../../assets/image/Chat Icon.svg';
import notificationsIcon from '../../../assets/image/Notifications Icon.svg';
import profileIcon from '../../../assets/image/Profile Icon.svg';
import signoutIcon from '../../../assets/image/signout.png';
import contestIcon from '../../../assets/image/contestIcon.svg';
import analyticsIcon from '../../../assets/image/analyticsIcon.svg';
// import log from '../../../assets/log.svg';
import logo from '../../../assets/logo.svg';
import { LaptopSidebar } from './style';
import PostModal from '../Modals/Post';
import RetrieveModal from '../Modals/Retrieve';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { LayoutContext } from '../../../store/context/LayoutContext';
import MenuList from './menuList';
import InterestModal from '../Modals/InterestsModal/Interest';
//  import { ModalContext } from '../../../store/context/ModalContext';
import {
  getMyInterest,
  getUnAddedInterest,
} from '../../pages/Wayagram/container';
import { ChatContext } from '../../../store/context/ChatContext';

export default function Sidebar(props) {
  const { currentPage, theme } = props;
  const history = useHistory();
  const [selectedInterest, seSelectedInterest] = useState([]);
  const [showRetrieveModal, setShowRetrieveModal] = useState(false);
  const { wayagramProfileId } = useContext(WayagramContext);
  const { unread } = useContext(ChatContext);
  const {
    toggleMobileSidebar,
    setShowPostModal,
    showPostModal,
    showInterestModal,
    setShowInterestModal,
    setUnAddedInterest,
    reload,
    setReload,
  } = useContext(LayoutContext);
  //  const { showCreatePin } = useContext(ModalContext);

  const handleInterestSuggestion = async () => {
    const resData = await getUnAddedInterest(wayagramProfileId);
    setUnAddedInterest(resData);
  };

  useEffect(() => {
    (async () => {
      if (wayagramProfileId) {
        handleInterestSuggestion();
        const myInterest = await getMyInterest(wayagramProfileId);
        seSelectedInterest(myInterest);
        // if (!myInterest?.length && myInterest) {
        //   setShowInterestModal(true);
        // }
      }
    })();
  }, [wayagramProfileId, reload]);
  const sideBarMenuItems = [
    {
      id: 1,
      text: 'Wayagram',
      img: wayagramIcon,
      activeText: 'wayagram',
      type: 'menu',
    },
    {
      id: 2,
      img: dashboardIcon,
      text: 'Discover',
      activeText: 'wayagram-discover',
      type: 'menu',
    },
    {
      id: 3,
      img: chatAndCallIcon,
      text: 'Shops',
      activeText: 'shops',
      type: 'menu',
    },
    {
      id: 4,
      img: chatAndCallIcon,
      text: 'Events',
      activeText: 'events',
      type: 'menu',
    },
    {
      id: 5,
      img: chatAndCallIcon,
      text: 'Donation',
      activeText: 'donation',
      type: 'menu',
    },
    {
      id: 25,
      img: bidIcon,
      text: 'Bidding',
      activeText: 'bid',
      type: 'menu',
    },
    {
      id: 6,
      img: analyticsIcon,
      text: 'Promotions Analytics',
      activeText: 'promotions-analytics',
      type: 'menu',
    },
    {
      id: 7,
      img: profileIcon,
      text: 'Profile',
      activeText: `wayagram-profile/${wayagramProfileId}`,
      type: 'menu',
    },
    {
      id: 8,
      img: contestIcon,
      text: 'Contest',
      activeText: `contest`,
      type: 'menu',
    },
    {
      id: 9,
      img: chatAndCallIcon,
      text: 'Bookmarks',
      activeText: 'wayagram-bookmarks',
      type: 'menu',
    },
    {
      id: 10,
      img: chatAndCallIcon,
      text: 'Top Clips',
      activeText: 'top-clips',
      type: 'menu',
    },
    {
      id: 11,
      img: chatAndCallIcon,
      text: 'Contacts',
      activeText: 'contacts',
      type: 'menu',
    },
    {
      id: 12,
      img: chatAndCallIcon,
      text: 'Chat & Calls',
      activeText: 'chat',
      type: 'menu',
      count: unread,
    },
    {
      id: 13,
      img: notificationsIcon,
      text: 'Notifications',
      activeText: 'notifications',
      type: 'menu',
    },
    {
      id: 14,
      img: notificationsIcon,
      text: 'Interests',
      activeText: 'interests',
      type: 'menu',
    },
    {
      id: 15,
      img: profileIcon,
      text: 'Settings',
      activeText: `settings`,
      type: 'menu',
    },
    {
      id: 16,
      img: profileIcon,
      text: 'Pages',
      activeText: `manage-pages`,
      type: 'menu',
    },

    {
      id: 17,
      img: profileIcon,
      text: 'Group',
      activeText: `manage-groups`,
      type: 'menu',
    },
    {
      id: 18,
      img: profileIcon,
      text: 'Support',
      activeText: `support`,
      type: 'menu',
      // url: process.env.REACT_APP_SUPPORT || 'https://wayagram.ng/support.html',
    },
    {
      id: 19,
      img: profileIcon,
      text: 'Activity Log',
      activeText: `activity-log`,
      type: 'menu',
    },
    {
      id: 20,
      img: wayapos,
      text: 'Web Collection (WAYAQUICK)',
      activeText: `web-pos`,
      type: 'external',
      url: process.env.REACT_APP_WAYABANK || 'https://app.wayaquick.com/login',
    },
    {
      id: 21,
      text: 'Bank Site (WAYABANK)',
      img: wayabank,
      activeText: 'wayabank',
      type: 'external',
      url: process.env.REACT_APP_WAYABANK || 'https://wayabank.ng/login',
    },
    {
      id: 22,
      text: 'Merchant/Agent POS Site (WAYAPOS)',
      img: profileIcon,
      activeText: 'terminal-pos',
      type: 'external',
      url: process.env.REACT_APP_WAYAPOS || 'https://app.wayapos.ng/login',
    },
    {
      id: 23,
      img: signoutIcon,
      text: 'Logout',
      activeText: 'logout',
      type: 'menu',
    },
  ];

  const otherMenuItems = [
    {
      id: 25,
      text: 'Privacy policy',
      img: profileIcon,
      activeText: '/privacy.html',
      type: 'external',
      url: '/privacy.html',
    },
    {
      id: 24,
      img: signoutIcon,
      text: 'Do More!',
      activeText: 'create',
      type: 'menu',
    },
  ];

  return (
    <LaptopSidebar toggle={toggleMobileSidebar} bgColor={theme.style}>
      {/* <PerfectScrollbar> */}
      <div className="sidebar-wrap">
        <div
          className={`sidebar ${
            theme.mode === 'light' ? 'light-theme' : 'dark-theme'
          }`}
        >
          <div
            className="navbar-logo"
            onClick={() => {
              history.push('/');
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={logo} alt="" />
          </div>
          <ul className="sidebar-menu-items">
            {sideBarMenuItems.map((item) => (
              <MenuList
                item={item}
                key={item.id}
                wayagramProfileId={wayagramProfileId}
                currentPage={currentPage}
                history={history}
                setShowRetrieveModal={setShowRetrieveModal}
                setShowPostModal={setShowPostModal}
                setShowInterestModal={setShowInterestModal}
                theme={theme}
              />
            ))}
          </ul>
          <ul className="sidebar-menu-items menu-bt pt-3">
            {otherMenuItems.map((item) => (
              <MenuList
                item={item}
                key={item.id}
                wayagramProfileId={wayagramProfileId}
                currentPage={currentPage}
                history={history}
                setShowRetrieveModal={setShowRetrieveModal}
                setShowPostModal={setShowPostModal}
                url
                theme={theme}
              />
            ))}
          </ul>
        </div>
      </div>
      {/* </PerfectScrollbar> */}
      {showPostModal ? (
        <PostModal
          showModal={showPostModal}
          hideModal={setShowPostModal}
          type="user"
        />
      ) : (
        ''
      )}
      {showRetrieveModal ? (
        <RetrieveModal
          showModal={showRetrieveModal}
          hideModal={setShowRetrieveModal}
        />
      ) : (
        ''
      )}
      {/* {(showInterestModal || !showCreatePin) && ( */}
      {showInterestModal && (
        <InterestModal
          showModal={showInterestModal}
          hideModal={setShowInterestModal}
          selectedInterest={selectedInterest}
          profileId={wayagramProfileId}
          seSelectedInterest={seSelectedInterest}
          reload={reload}
          setReload={setReload}
          step={0}
        />
      )}
    </LaptopSidebar>
  );
}

Sidebar.propTypes = {
  currentPage: PropTypes.string.isRequired,
};
