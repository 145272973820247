import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';

export const WalletContext = createContext();

export const WalletContextProvider = (props) => {
  const { children } = props;
  const [walletList, setWalletList] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [reload, setReload] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [defaultWallet, setDefaultWallet] = useState({});

  const getWalletList = async (userId) => {
    setWalletLoading(true);
    const profileId = localStorage.getItem('profileId');
    const res = await httpGet(
      // `/wallet/find/by/userId/${userId}`,
      `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}/${profileId}`
    );

    if (res && res?.status) {
      setWalletList(res.data);
      if (res.data.length && res.data.length < 2) {
        const result = res.data.filter((item) => item?.walletDefault);
        setDefaultWallet(result[0]);
      }
      setWalletLoading(false);
    }
  };

  const getTransactionHistory = async () => {
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/find/transactions/${defaultWallet?.accountNo}?page=0&size=${pageSize}`
    );

    if (res?.status) {
      setTransactionHistory(res?.data?.content);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getWalletList(userId);
    // getTransactionHistory();
  }, [reload]);

  useEffect(() => {
    if (defaultWallet.accountNo) {
      getTransactionHistory();
    }
  }, [pageSize, defaultWallet?.accountNo]);

  return (
    <WalletContext.Provider
      value={{
        setWalletList,
        walletList,
        reload,
        setReload,
        walletLoading,
        setWalletLoading,
        transactionHistory,
        setPageSize,
        pageSize,
        setDefaultWallet,
        defaultWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
